<template>
  <div>
    <CRow>
      <CCol sm="12">
        <ProductsTable
          :items="products"
          hover
          striped
          border
          small
          fixed
          caption="Productos"
          @refresh="refreshItems"
          :permissions="permissions"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ProductsTable from '../../components/products/ProductsTable.vue'
import ws from '../../services/products';
import account_ws from '../../services/account';
import store from '../../store'

export default {
  name: 'Products',
  components: { ProductsTable },
  data: function () {
		return {
            products: [],
            permissions: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await ws.get();

    if(response.type == "success"){
      this.products = response.data;
    }
    
    let response2 = await account_ws.getInfo(); 

    if(response2.type == "success"){
      this.permissions = response2.data.user.permissions;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.products = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >   
        <CForm>
            
            <CRow>
              <CCol md="8">
                <CSelect
                  label="Proveedor: "
                  :value.sync="$v.form.provider_id.$model"
                  :options="providersOptions"
                  :isValid="checkIfValid('provider_id')"
                  invalidFeedback="Este campo es requerido."
                  @change="changeProvider"
                />
              </CCol>
              <CCol md="4">
                <CSelect
                  label="Marca: "
                  :value.sync="$v.form.brand_id.$model"
                  :options="brandsOptions"
                  :isValid="checkIfValid('brand_id')"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="4">
                <CInput
                  label="Código:"
                  :lazy="false"
                  :value.sync="$v.form.code.$model"
                  :isValid="checkIfValid('code')"
                  placeholder=""
                  autocomplete="given-code"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
              <CCol md="8">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers, minValue } from "vuelidate/lib/validators"
import ws from '../../services/products';
import providers_ws from '../../services/providers';
import brands_ws from '../../services/brands';

export default {
  name: 'ProductModal',
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Nuevo Producto",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      providers: [],
      providersOptions: [],
      brands: [],
      brandsOptions: []
    }
  },
  mounted: async function() {
    let response = await providers_ws.get(); 

    if(response.type == "success"){
      this.providers = response.data;

      this.providersOptions = await this.$parseSelectOptionsOrdered(this.providers, "id", "name", [{value: '0', label: 'NO DEFINIDO'}]);
    }
  },
  methods: {
    async changeProvider () {
      let filters = this.getFilters();
      let response = await brands_ws.getFiltered(filters);

      if(response.type == "success"){
        this.brands = response.data;

        this.brandsOptions = await this.$parseSelectOptionsOrdered(this.brands, "id", "name", [{value: '0', label: 'NO DEFINIDA'}]);
      }
    },
    getFilters(){
      let filters = {
        provider : this.form.provider_id
      };

      return filters;
    },
    async storeModal (provider_filtered) {
      this.showModal = true;
      this.color = "info";
      this.title = 'Nuevo Producto';
      this.isEdit = false;
      this.submitted = false;

      this.brands = [];

      if(provider_filtered > 0){
        let filters = { provider : provider_filtered };
        let response = await brands_ws.getFiltered(filters);

        if(response.type == "success"){
          this.brands = response.data;
        }
      }

      this.brandsOptions = await this.$parseSelectOptionsOrdered(this.brands, "id", "name", [{value: '0', label: 'NO DEFINIDA'}]);

      this.form = {
          id: 0,
          code: '',
          name: '',
          provider_id: provider_filtered,
          brand_id: 0
      }
    },
    updateModal (item) {
      this.showModal = true;
      this.color = "warning";
      this.title = 'Editar Producto';
      this.isEdit = true;
      this.submitted = false;

      if(item.provider_id != this.form.provider_id){
        this.form.provider_id = item.provider_id;
        this.changeProvider();
      }

      this.form = {
          id: item.id,
          code: item.code,
          name: item.name,
          provider_id: item.provider_id,
          brand_id: item.brand_id
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          code: '',
          name: '',
          provider_id: 0,
          brand_id: 0
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      provider_id: {
        required,
        minValue: minValue(1)
      },
      brand_id: {
        required,
        minValue: minValue(1)
      },
      code: {
        required,
        minLength: minLength(3)
      },
      name: {
        required,
        minLength: minLength(3)
      }
    }
  },
}
</script>